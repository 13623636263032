<template>
  <div>
    <div slot="label" class="form-label" style="font-size: 1.2rem">
      {{ __("Display Date Format") }}
      <el-popover
        placement="top-start"
        width="500"
        trigger="hover"
        content=""
        style="margin-left: 4px"
      >
        <p>{{ __("Year") }} - yyyy</p>
        <p>{{ __("Month") }} - MM</p>
        <p>{{ __("Day") }} - dd</p>
        <i
          slot="reference"
          style="font-size: 0.938rem"
          class="el-icon-info icon"
        ></i>
      </el-popover>
    </div>
    <el-select
      v-model="display_date"
      :placeholder="__('Select')"
      class=""
      style="width: 50%"
      default-first-option
      @change="changeDisplayDateFormatValue"
      :clearable="true"
    >
      <el-option
        v-for="item in displayDateFormats"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        <span style="float: left">{{ item.label }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">
          {{ item.value }}
        </span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "DisplayDateFormatDropdown",
  props: {
    value: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      display_date: ""
    };
  },
  computed: {
    ...mapGetters("app", {
      displayDateFormats: "displayDateFormats"
    })
  },

  methods: {
    /**
     * emit change display date format back to parent
     * @param displayDateFormat
     */
    changeDisplayDateFormatValue(displayDateFormat) {
      this.$emit("display-date-format-changed", displayDateFormat);
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.display_date = _.cloneDeep(newVal);
        }
      }
    }
  },
  created() {
    this.display_date = _.cloneDeep(this.value);
  }
};
</script>

<style scoped></style>
