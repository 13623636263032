<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Name") }}</div>
    <div class="details">{{ contentFormInfo.display_name }}</div>
    <div class="titles">{{ __("Email") }}</div>
    <div class="details">{{ contentFormInfo.email }}</div>
    <div v-if="canViewAccountMappings">
      <div class="titles" id="account-mappings">{{ __("Accounts") }}</div>
      <div
        class="details"
        v-for="(accounts, parentIndex) in contentFormInfo.account_mappings"
        :key="parentIndex"
      >
        <div v-for="(account, index) in accounts.accounts" :key="index">
          {{ account.ac_name }}
        </div>
      </div>
    </div>

    <div v-if="this.userStatus">
      <div class="titles">{{ __("Last login time") }}</div>
      <div class="details">
        {{ formattedDateTime(contentFormInfo.login_time) }}
      </div>
    </div>
    <div class="details">
      <el-button
        v-show="
          this.selectedAccountId === 'all' &&
            this.selectedServiceProviderId === 'all' &&
            this.contentFormInfo.ac_sp_map_role_permission.length > 0
        "
        slot="reference"
        @click="showMapping"
        type="text"
      >
        {{ __("See Mappings") }}
      </el-button>
      <el-dialog
        v-if="showMappingModel"
        :visible.sync="showMappingModel"
        :close-on-click-modal="false"
        :show-close="true"
        append-to-body
        destroy-on-close
        lock-scroll
        :title="__('Account Maps')"
      >
        <el-table
          v-show="this.userRole === 'system'"
          :data="contentFormInfo.ac_sp_map_role_permission"
        >
          <el-table-column type="expand" prop="permissions">
            <template slot-scope="scope">
              <el-cascader-panel
                :options="scope.row.permission_tree"
              ></el-cascader-panel>
            </template>
          </el-table-column>
          <el-table-column :label="__('NAME')" prop="name"></el-table-column>
          <el-table-column :label="__('TYPE')" prop="type"></el-table-column>
          <el-table-column :label="__('ROLE')" prop="role"></el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <div class="details">
      <el-button
        v-show="!this.isActiveUser"
        slot="reference"
        @click="showSetPwdModel = true"
        type="text"
      >
        {{ __("Set Password") }}
      </el-button>
      <el-dialog
        v-if="showSetPwdModel"
        :visible.sync="showSetPwdModel"
        :close-on-click-modal="false"
        :show-close="true"
        width="25%"
        append-to-body
        lock-scroll
        destroy-on-close
        :title="__('Set User Password')"
      >
        <CreateProfile
          set-internally
          :id="this.contentFormInfo.user_id"
          :profile-to-edit="{
            email: this.contentFormInfo.email
          }"
          @password-set="handlePwdSet"
        />
      </el-dialog>
    </div>
    <div v-if="userStatus">
      <el-tooltip
        :disabled="isRoleManagementAllowed"
        placement="top"
        effect="studio-theme"
      >
        <span slot="content" style="white-space: pre-line">
          {{ __("You don't have sufficient \nprivileges to edit this user.") }}
        </span>
        <el-button
          @click="handleEdit"
          class="editBtn"
          v-show="allowEditMode"
          :disabled="!isRoleManagementAllowed"
          >{{ __("Edit") }}
        </el-button>
      </el-tooltip>
      <el-tooltip
        :disabled="isRoleManagementAllowed"
        placement="top"
        effect="studio-theme"
      >
        <span slot="content" style="white-space: pre-line">
          {{
            __("You don't have sufficient \nprivileges to delete this user.")
          }}
        </span>
        <el-button
          :disabled="!this.canWrite() || !isRoleManagementAllowed"
          @click="handleDelete(contentFormInfo)"
          class="deleteBtn"
          >{{ __("Delete") }}
        </el-button>
      </el-tooltip>
      <div class="details" v-show="!allowEditMode" style="padding-top: 20px">
        {{
          __(
            "Please select All Accounts under a Service Provider to edit user info."
          )
        }}
      </div>
    </div>
    <div class="details" v-else>
      <div v-if="!pendingDelete">
        <el-button
          :disabled="!this.canWrite()"
          @click="handleReactivate(contentFormInfo)"
          class="editBtn"
          >{{ __("Re-activate") }}</el-button
        >
        <el-button
          :disabled="!this.canWrite()"
          @click="handleRevoke(contentFormInfo)"
          class="deleteBtn"
          >{{ __("Revoke") }}
        </el-button>
      </div>
      <div class="titles" v-else>
        {{ __("User deletion in progress.") }}
        <el-button
          :disabled="!this.canWrite()"
          @click="handleForceDelete(contentFormInfo)"
          class="deleteBtn"
          >{{ __("Force Delete") }}
        </el-button>
      </div>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      :before-close="closeModal"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <!-- Intentionally use setPageHeaderTitle computed prop because the prefix is invite & edit -->
                <page-header
                  style="padding-left: 0"
                  :title="__('User')"
                  :contentId="id"
                />
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="email" :label="__('Email Address')">
                  <el-input v-model="contentForm.email" v-loading="isChecking">
                  </el-input>
                  <!-- Show resend info message only while creating -->
                  <div v-if="userExists && id === -1" class="info">
                    <div class="warning-exclamation" />
                    <div style="margin-left: 5px; display: flex">
                      <div>
                        <!-- eslint-disable-next-line -->
                        {{ __("User email already exists. Please, proceed if you need to resend the invitation for this Service Provider.") }}
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="selectedServiceProviderId !== 'all'">
              <el-row
                v-if="userType !== 'ac' && selectedAccountId === 'all'"
                type="flex"
              >
                <el-col :span="12" :offset="6">
                  <el-form-item prop="ac_scope" :label="__('Access Scope')">
                    <el-radio-group
                      v-model="contentForm.account_mappings[0].scope"
                      @change="setScope"
                    >
                      <el-radio label="ac">{{ __("Account") }}</el-radio>
                      <el-radio label="sp">{{
                        __("Service Provider")
                      }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row
                type="flex"
                v-if="
                  selectedServiceProviderId !== 'all' &&
                    contentForm.account_mappings[0].scope === 'ac' &&
                    selectedAccountId === 'all'
                "
              >
                <el-col :span="12" :offset="6">
                  <div class="flex justify-between">
                    <div class="form-label">{{ __("Add Accounts") }}</div>
                    <div>
                      <el-button
                        icon="el-icon-plus"
                        circle
                        @click="addAccountPermissions()"
                      ></el-button>
                    </div>
                  </div>
                  <el-form-item prop="account_permissions">
                    <account-permissions
                      v-for="(account_permission, index) in accountPermissions"
                      :key="index"
                      :index="index"
                      :account_permission="account_permission"
                      :id="contentFormInfo.id"
                      :selected_roles="selected_roles"
                      :selected_accounts="selected_accounts"
                      :available_roles="roles"
                      @remove="removeAccountPermissions(index)"
                      v-on:roleSelected="updateSelectedRoles($event)"
                      v-on:accountsSelected="updateSelectedAccounts($event)"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row
                type="flex"
                v-if="
                  contentForm.account_mappings[0].scope === 'ac' &&
                    selectedAccountId !== 'all'
                "
              >
                <el-col :span="12" :offset="6">
                  <el-form-item
                    prop="account_mappings"
                    :label="__('Accounts')"
                    class="account-select"
                  >
                    <div
                      v-if="
                        !!accountName(selectedAccountId) &&
                          selectedAccountId !== 'all'
                      "
                    >
                      The user will have access to
                      <span class="account-name">
                        '{{ accountName(selectedAccountId) }}'</span
                      >
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row
              type="flex"
              v-if="
                selectedServiceProviderId === 'all' ||
                  selectedAccountId !== 'all' ||
                  (contentForm.account_mappings[0].scope !== 'ac' &&
                    selectedAccountId === 'all')
              "
            >
              <el-col :span="6" :offset="6">
                <el-form-item prop="user_role" :label="__('Role')">
                  <el-select
                    style="width: 100%"
                    v-model="contentForm.user_role"
                    :placeholder="__('Select Role')"
                    v-loading="isFetchingRoles"
                    @change="setPermissions"
                    default-first-option
                    filterable
                  >
                    <el-option
                      v-for="item in assignableRoles"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <div v-if="contentForm.sync_permission" class="info">
                    <div style="margin-left: 5px; display: flex">
                      <div style="color: gray">
                        <!-- eslint-disable-next-line -->
                        {{ __("This would sync permissions attached to the selected role.") }}
                        <br />
                        <!-- eslint-disable-next-line -->
                        {{ __("Sync for removing permissions from a role is applied by default.") }}
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="sync_permission" label="">
                  <el-checkbox
                    v-show="!isSystemRole"
                    :disabled="!contentForm.user_role"
                    v-model="contentForm.sync_permission"
                    style="padding-left:15px; padding-top: 28px"
                    @change="handleSyncPermissionChange"
                    >{{ __("Sync Permission") }}</el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <div
              v-if="
                this.show_permissions &&
                  !isSystemRole &&
                  !contentForm.sync_permission &&
                  contentForm.user_role !== ''
              "
            >
              <el-row type="flex" style="max-height: 32px; margin-bottom: 10px">
                <el-col style="width: 120px" :offset="6">
                  <el-form-item :label="__('Permissions')"> </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      __('Reset to permissions attached to selected role')
                    "
                    placement="left-end"
                  >
                    <el-button
                      size="mini"
                      round
                      @click="resetToRolePermission"
                      >{{ __("Reset To default") }}</el-button
                    >
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="resetPermissionContent"
                    placement="right-end"
                  >
                    <el-button
                      v-show="formAction === 'edit'"
                      type="primary"
                      size="mini"
                      round
                      @click="resetToOldPermissions"
                      >{{ __("Reset to Old Permission") }}</el-button
                    >
                  </el-tooltip>
                </el-col>
              </el-row>
              <div
                v-if="
                  selectedServiceProviderId !== 'all' &&
                    contentForm.account_mappings[0].scope === 'sp'
                "
              >
                <el-row type="flex">
                  <el-col :span="12" :offset="6">
                    <div class="info">
                      <div style="margin:5px; display: flex">
                        <div>
                          <!-- eslint-disable-next-line -->
                          {{ __("Build and Analyse (read/write) permissions are given by default when access scope is set to Service Provider") }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item prop="user_permissions">
                    <el-cascader-panel
                      ref="permCascader"
                      style="width: 100%"
                      v-show="contentForm.user_role !== ''"
                      v-model="contentForm.user_permissions"
                      :key="cascaderKey"
                      :options="permissions"
                      :props="perm_props"
                      clearable
                      filterable
                      v-loading="isFetchingPermissions"
                      @change="permissionChange"
                    ></el-cascader-panel>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div
              v-else-if="
                selectedServiceProviderId === 'all' ||
                  (contentForm.account_mappings[0].scope !== 'ac' &&
                    selectedAccountId === 'all') ||
                  selectedAccountId !== 'all'
              "
              style="margin-top: 0;min-height: 50px"
            >
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-button
                    :disabled="
                      !permissions.length ||
                        isSystemRole ||
                        contentForm.sync_permission ||
                        contentForm.user_role === ''
                    "
                    type="text"
                    @click="showPermissions"
                    v-loading="isFetchingPermissions"
                    >{{ __("Edit Permissions") }}</el-button
                  >
                </el-col>
              </el-row>
              <div v-if="isSystemRole">
                <el-row type="flex">
                  <el-col :span="12" :offset="6">
                    <div class="info" style="margin: 10px 0">
                      <div class="warning-exclamation" />
                      <div style="margin:5px; display: flex">
                        <div>
                          <!-- eslint-disable-next-line -->
                          {{ __("Permissions not alterable for system role. Base role permissions are applied.") }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item label="" prop="display_date_format">
                  <display-date-format-dropdown
                    v-model="contentForm.display_date_format"
                    @display-date-format-changed="displayDateFormatChanged"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Timezone')" prop="default_timezone">
                  <timezone-dropdown
                    :default_timezone="contentForm.default_timezone"
                    @timezone-changed="handleTimezoneChange"
                  /> </el-form-item
              ></el-col>
            </el-row>
            <div v-if="id !== -1">
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item prop="password" class="custom-form-item text">
                    <div slot="label" class="form-label">
                      <div>{{ __("Password") }}</div>
                      <div>
                        <el-popover
                          placement="top-start"
                          width="500"
                          trigger="hover"
                          :content="passwordPopoverContent"
                        >
                          <i slot="reference" class="el-icon-info icon"></i>
                        </el-popover>
                      </div>
                    </div>
                    <el-input
                      type="password"
                      id="password"
                      v-model="contentForm.password"
                      :placeholder="__('Please input password')"
                      autocomplete="off"
                      show-password
                    />
                    <password-meter
                      :password="contentForm.password"
                      @score="onScore"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="password_confirmation"
                    :label="__('Confirm Password')"
                    class="custom-form-item text"
                  >
                    <el-input
                      v-model="contentForm.password_confirmation"
                      :placeholder="__('Please input the password again')"
                      type="password"
                      autocomplete="off"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <save-button
                  type="primary"
                  :disabled="!(this.canWrite() && this.allowCreateUser)"
                  @click="submitForm"
                  class="submitBtn"
                  :primaryKey="id"
                />
                <el-button @click="closeModal" class="cancelBtn">{{
                  __("Cancel")
                }}</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { validEmail } from "@/utils/validate";
import { validateUniqueEmailAndInvite } from "@/api/users";
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import CreateProfile from "@/views/profile/users/pages/createProfile";
import AccountPermissions from "@/views/manage/users/components/accountPermissions";
import PasswordMeter from "@/components/PasswordMeter/password-meter.vue";
import TimezoneDropdown from "@/components/TimezoneDropdown";
import DisplayDateFormatDropdown from "@/components/DisplayDateFormatDropdown";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    DisplayDateFormatDropdown,
    AccountPermissions,
    CreateProfile,
    PageHeader,
    PasswordMeter,
    TimezoneDropdown,
    SaveButton
  },
  data() {
    let validateEmail = async (rule, value, callback) => {
      try {
        // validate if email is valid
        if (!validEmail(value)) {
          callback(new Error(__("Please enter valid email")));
        } else {
          // validate whether an email is unique
          this.isChecking = true;
          let user_data = await this.checkEmailAndInviteValidity(value);
          if (user_data.exists && !user_data.invite_valid) {
            if (value !== this.contentFormInfo.email) {
              callback(new Error(user_data.invite_validation_msg));
            }
          } else {
            this.userExists = user_data.exists;
          }
          this.isChecking = false;
          callback();
        }
      } catch (e) {
        this.isChecking = false;
      }
    };
    let checkSelectedAccount = (rule, value, callback) => {
      // If account isn't selected from dropdown
      if (this.scope === "ac" && !value[0].accounts.length) {
        callback(__("account is required"));
      } else {
        callback();
      }
    };
    let checkSelectedAccessScope = (rule, value, callback) => {
      // If account scope isn't selected from dropdown
      if (!this.contentForm.account_mappings[0].scope) {
        callback(__("access scope is required"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      this.$nextTick(() => {
        if (value) {
          if (this.pwdScore < 1) {
            callback(__("Password is not secure enough"));
          }
          if (!_.isEmpty(this.contentForm.password_confirmation)) {
            this.$refs.contentForm.validateField("password_confirmation");
          }
        }
        callback();
      });
    };
    const validateConfirmPassword = (rule, value, callback) => {
      if (this.contentForm.password) {
        if (value !== this.contentForm.password) {
          callback(__("Two passwords don't match!"));
        }
      }
      callback();
    };

    const validateAccountPermissions = (rule, value, callback) => {
      if (this.accountPermissions.length > 0) {
        this.accountPermissions.forEach(account_permission => {
          if (
            account_permission.accounts.length === 0 ||
            account_permission.user_role === ""
          ) {
            callback(__("Please select accounts and role for user"));
          }
        });
        callback();
      } else {
        callback(__("Please select at least one account and role for user"));
      }
    };

    return {
      perm_props: { multiple: true },
      isSubmitting: false,
      formSelectOptions: [],
      originalContentForm: {},
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        ac_scope: [
          {
            required: true,
            trigger: "change",
            validator: checkSelectedAccessScope
          }
        ],
        account_mappings: [
          {
            required: true,
            trigger: "change",
            validator: checkSelectedAccount
          }
        ],
        user_role: [
          {
            required: true,
            trigger: "change",
            message: __("role is required")
          }
        ],
        password: [
          { required: false, validator: validatePassword, trigger: "change" }
        ],
        password_confirmation: [
          {
            required: false,
            validator: validateConfirmPassword,
            trigger: "change"
          }
        ],
        account_permissions: [
          {
            required: false,
            validator: validateAccountPermissions,
            trigger: "blur"
          }
        ]
      },
      defaultProps: {
        children: "children",
        label: "label"
      },
      scope: "ac",
      isChecking: false,
      userExists: false,
      show_permissions: false,
      showMappingModel: false,
      showSetPwdModel: false,
      pwdScore: null,
      accountPermissions: [
        {
          accounts: [],
          user_role: null,
          sync_permission: false,
          user_permissions: []
        }
      ],
      selected_roles: [],
      selected_accounts: []
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userAccountSwitcherOptions: state => state.userAccountSwitcherOptions,
      userRole: state => state.userRole,
      userType: state => state.userType
    }),

    ...mapGetters("app", {
      accountName: "accountName",
      formattedDateTime: "formattedDateTime"
    }),

    ...mapGetters("app", {
      accountsForCreate: "accountsForCreate"
    }),

    ...mapGetters("serviceproviders", {
      accounts: "accounts"
    }),
    ...mapState("serviceproviders", {
      loadingAccounts: "loading"
    }),
    ...mapState("roles", {
      roles: "roles",
      isFetchingRoles: "isLoading"
    }),
    ...mapState("permissions", {
      permissions: "permissions",
      isFetchingPermissions: "isLoading"
    }),
    hasSystemRole() {
      return _.find(this.content.roles.data, item => {
        return item.name === "system";
      });
    },
    allowEditMode() {
      return (
        this.contentForm.user_status === "Active" &&
        ((this.selectedServiceProviderId === "all" &&
          this.selectedAccountId === "all" &&
          this.contentForm.current_role !== "" &&
          this.contentForm.ac_sp_map_role_permission.length === 0) ||
          (this.selectedServiceProviderId !== "all" &&
            this.selectedAccountId === "all") ||
          (this.selectedServiceProviderId !== "all" &&
            this.selectedAccountId !== "all" &&
            this.userAccountSwitcherOptions[0]["accounts"].length === 1))
      );
    },
    allowCreateUser() {
      return !(
        (this.selectedServiceProviderId === "all" &&
          this.selectedAccountId === "all" &&
          this.contentForm.ac_sp_map_role_permission.length > 0) ||
        this.contentForm.current_role === ""
      );
    },
    userStatus() {
      if (
        this.contentFormInfo.user_status === "false" ||
        this.contentFormInfo.user_status === "Inactive"
      ) {
        return false;
      }
      if (this.contentFormInfo.user_status === "Active") {
        return true;
      }
      return true;
    },
    pendingDelete() {
      return this.contentFormInfo.pending_delete;
    },

    mappedScope() {
      return contentForm => {
        return (
          contentForm.account_maps &&
          contentForm.account_maps.length &&
          contentForm.account_maps[0].scope
        );
      };
    },
    defaultPermissions() {
      return contentForm => {
        return _.find(this.roles, function(role) {
          return role.id === contentForm.user_role;
        }).role_permissions;
      };
    },
    newRolePermissions() {
      return role_id => {
        return _.find(this.roles, function(role) {
          if (role.id === role_id) {
            return role.role_permissions;
          }
        }).role_permissions;
      };
    },
    isSystemRole() {
      let vm = this;
      if (vm.scope === "system") {
        return _.some(this.roles, function(role) {
          if (role.id === vm.contentForm.user_role) {
            return role.scope === "system" && role.name === "system";
          }
        });
      }
      return false;
    },
    isActiveUser() {
      if (!_.isBoolean(this.contentFormInfo.user_status)) {
        return this.contentFormInfo.user_status.toLowerCase() === "active";
      }
      return this.contentFormInfo.user_status;
    },
    isRoleManagementAllowed() {
      if (this.userRole === "system") {
        return true;
      }
      let loginUserAccScope = this.accountsForCreate.map(
        acc => acc.value.ac_id
      );
      let curUserAccScope = this.contentForm.account_permissions
        .map(account_permission =>
          account_permission.accounts.map(acc => acc.ac_id)
        )
        .flat();

      return curUserAccScope.every(ac_id => loginUserAccScope.includes(ac_id));
    },
    cascaderKey() {
      return this.contentFormInfo.id > 0
        ? this.contentFormInfo.id
        : Math.random();
    },
    setPageHeaderTitle: function() {
      return this.id === -1 ? __("Invite User") : __("Edit User");
    },

    assignableRoles() {
      // If the auth user scope is ac, they should only be able to select the same role they have for a given account.
      if (this.userType === "ac" && this.selectedAccountId !== "all") {
        let roleForSelectedAc = this.$auth
          .user()
          .roles_with_scope.find(roleObj => {
            return roleObj["ac_id"] === this.selectedAccountId;
          });

        let selectedRole = this.roles.find(roleObj => {
          return roleObj["name"] === roleForSelectedAc.name;
        });

        roleForSelectedAc.role_permissions = _.clone(
          selectedRole.role_permissions
        );

        let availableRoleNames = this.getSuperSetsForRole(
          roleForSelectedAc,
          this.roles
        );

        return this.roles.filter(role =>
          availableRoleNames.includes(role.name)
        );
      }
      return this.roles;
    },

    /**
     * Getter & setter for the user's preferred language
     */
    preferredLang: {
      get: function() {
        const userLang = _.get(this.contentForm, "language", "en");
        return _.isNil(userLang) ? "en" : userLang;
      },
      set: function(newVal) {
        this.$set(this.contentForm, "language", newVal);
      }
    },

    /**
     * Content for the reset permission tooltip
     * @returns {String} Translated string for the reset permission tooltip
     */
    resetPermissionContent() {
      // eslint-disable-next-line
      return __("Reset user permissions to the state they were in when you initiated edit mode");
    },

    /**
     * Content for the password popover
     * @returns {String} Translated content for the password popover
     */
    passwordPopoverContent() {
      // eslint-disable-next-line
      return __('Password must be 8 characters long. It should contain at least one number, one special character and both upper case and lower case. Password should not be previously used password or a word which is easy to guess.');
    },

    canViewAccountMappings() {
      return (
        this.formAction &&
        this.formAction !== "add" &&
        !this.hasSystemRole &&
        this.contentFormInfo.account_mappings.length > 0
      );
    }
  },

  methods: {
    ...mapActions("users", {
      createUser: "createUser",
      updateUser: "updateUser",
      createUserMapping: "createUserMapping",
      updateUserMapping: "updateUserMapping",
      deleteContentMethod: "deleteUser",
      forceDeleteContentMethod: "forceDeleteUser",
      undoDeleteContent: "undoDeleteUser",
      revokeInvitedUser: "revokeInvitedUser",
      reVerifyInactiveUser: "reVerifyInactiveUser"
    }),
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProvider: "fetchAccountsForServiceProvider"
    }),
    ...mapActions("permissions", {
      getAcScopePermissions: "getAcScopePermissions",
      getRolePermissions: "getRolePermissions",
      setEmptyPermission: "setEmptyPermission"
    }),
    ...mapActions("roles", {
      getRoles: "getRoles",
      setEmptyRole: "setEmptyRole"
    }),
    /**
     * update the timezone for user
     * @param timezone
     * @returns {Promise<void>}
     */
    async handleTimezoneChange(timezone) {
      this.contentForm.default_timezone = timezone;
    },

    /**
     * handle display date format changed
     * @param displayDateFormat
     */
    displayDateFormatChanged(displayDateFormat) {
      this.contentForm.display_date_format = displayDateFormat;
    },

    onScore({ score }) {
      // score: 0 -> 4
      // strength one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
      this.pwdScore = score;
    },
    permissionChange(value) {
      let checkedNodes = this.$refs.permCascader.getCheckedNodes(true);
      let mandatory_permission = this.mandatory_permission(checkedNodes, value);
      if (
        mandatory_permission !== undefined &&
        mandatory_permission.length > 0
      ) {
        let clone_permission = _.clone(this.contentForm.user_permissions);
        clone_permission.push(mandatory_permission[0]);
        this.contentForm.user_permissions = clone_permission;
      }
    },
    changePermissionsArray(array) {
      this.contentForm.user_permissions.push(array);
    },
    expandChange(value) {
      console.log(value);
    },
    handleRevoke(user) {
      if (this.canWrite()) {
        this.revokeInvitedUser(user);
        this.handleCancel();
      }
    },
    handleForceDelete(user) {
      if (this.canWrite()) {
        this.$confirm(
          __(
            "Please confirm that you want to proceed with deleting this user."
          ),
          __("Confirmation"),
          {
            confirmButtonText: __("Yes"),
            cancelButtonText: __("No"),
            type: "warning"
          }
        )
          .then(() => {
            this.forceDeleteContentMethod(user);
          })
          .catch(() => {
            // Do nothing
          });
      }
    },

    getSuperSetsForRole(selectedRole, availableRoles) {
      let rolesSuperSet = _.filter(
        availableRoles,
        role =>
          _.intersectionWith(
            role.role_permissions,
            selectedRole.role_permissions,
            _.isEqual
          ).length === role.role_permissions.length
      );

      return rolesSuperSet
        .filter(item => _.has(item, "name"))
        .map(item => item["name"]);
    },

    handleReactivate(user) {
      if (this.canWrite()) {
        let self = this;
        this.reVerifyInactiveUser(user)
          .then(data => {
            self.$message({
              type: "info",
              message: data.message
            });
          })
          .catch(data => {
            self.$message({
              type: "error",
              message: data.message
            });
          });
        this.handleCancel();
      }
    },
    showPermissions() {
      this.show_permissions = true;
    },

    validateAndSubmit() {
      this.$refs["userBaseForm"].validateForm();
    },

    async checkEmailAndInviteValidity(value) {
      try {
        this.isChecking = true;
        let data = { email: value, selectedAccountId: this.selectedAccountId };
        const res = await validateUniqueEmailAndInvite(data);
        this.isChecking = false;
        return res.data;
      } catch (e) {
        this.isChecking = false;
      }
      return false;
    },

    async setScope(change) {
      this.scope = change;
      this.show_permissions = false;
      if (
        change !== "system" &&
        change !== this.mappedScope(this.contentForm)
      ) {
        this.contentForm.user_role = "";
      } else {
        this.contentForm.user_role = this.contentFormInfo.user_role;
      }

      if (
        this.selectedAccountId === "all" &&
        this.selectedServiceProviderId !== "all" &&
        this.contentForm.account_mappings[0].scope === "ac"
      ) {
        this.scope = "ac";
      }

      if (
        change === "ac" &&
        this.selectedAccountId === "all" &&
        this.selectedServiceProviderId !== "all" &&
        _.isEmpty(this.contentForm.account_permissions)
      ) {
        this.scope = "ac";
        this.accountPermissions = [
          {
            accounts: [],
            user_role: ""
          }
        ];
      }

      await this.getRoles({ scope: this.scope });
      await this.getAcScopePermissions(this.scope);
    },

    async setPermissions(role_id) {
      // get role permissions for selected role to over-ride base permissions
      await this.getRolePermissions({
        scope: this.scope,
        role_id: this.contentForm.user_role
      });
      // if creating a user, set role based permission otherwise show the user stored permissions
      if (this.id === -1) {
        this.contentForm.user_permissions = this.newRolePermissions(role_id);
      }
    },
    handleSyncPermissionChange(value) {
      if (value) {
        this.resetToRolePermission();
      }
    },
    resetToRolePermission() {
      // switch to the role based permissions
      this.contentForm.user_permissions = this.defaultPermissions(
        this.contentForm
      );
    },
    resetToOldPermissions() {
      // while editing if user wants to switch to thier old permissions
      if (this.contentFormInfo.user_permissions.length) {
        this.contentForm.user_permissions = this.contentFormInfo.user_permissions;
      }
    },
    closeModal() {
      this.show_permissions = false;
      this.userExists = false;
      this.setEmptyPermission();
      this.setEmptyRole();
      this.handleCancel();
    },

    updateContentFormForAccountPermissions() {
      this.originalContentForm = Object.assign({}, this.contentForm);

      if (
        this.selectedServiceProviderId !== "all" &&
        this.contentForm.account_mappings[0].scope === "ac" &&
        this.selectedAccountId === "all"
      ) {
        let ac_permissions = this.accountPermissions[0];
        this.contentForm.user_role = ac_permissions.user_role;
        this.contentForm.sync_permission = ac_permissions.sync_permission;
        this.contentForm.user_permissions = ac_permissions.user_permissions;
        this.contentForm.account_permissions = this.accountPermissions.filter(
          account_permission => account_permission.accounts.length > 0
        );
        this.contentForm.account_mappings = [
          { scope: "ac", accounts: [], service_providers: [] }
        ];
      } else {
        this.account_permissions = [];
        this.contentForm.account_permissions = [];
      }
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          this.updateContentFormForAccountPermissions();
          let userUpdatedMessage = "User updated successfully";
          const process = this.id === -1 ? this.createUser : this.updateUser;
          this.contentForm.sp_id = this.selectedServiceProviderId;
          if (
            !(
              this.contentForm.user_status === false ||
              this.contentForm.user_status === true
            )
          ) {
            this.contentForm.user_status =
              this.contentForm.user_status.toLowerCase() === "active";
          }
          if (!_.isEmpty(this.contentForm.password)) {
            userUpdatedMessage += ", And you updated the password.";
          }
          this.contentForm.resend_invitation = this.userExists;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: data.message
                  })
                : this.$message({
                    type: "success",
                    message: userUpdatedMessage
                  });

              EventBus.$emit("list-changed", data.data);
              this.closeModal();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.contentForm = this.originalContentForm;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    },
    showMapping() {
      this.showMappingModel = true;
    },
    handlePwdSet(val) {
      this.showSetPwdModel = false;
      if (val) {
        this.$message({
          type: "success",
          // eslint-disable-next-line
          message: __("User updated successfully. And you updated the password.")
        });
      } else {
        this.$message({
          type: "error",
          // eslint-disable-next-line
          message: __("Cannot set user password. Please contact your administrator.")
        });
      }
      EventBus.$emit("list-changed");
    },

    async initializeModal() {
      const accounts = this.accounts(this.selectedServiceProviderId);
      const accountOptions = accounts.map(account => {
        return {
          label: account.label,
          value: account.value.ac_id
        };
      });
      this.formSelectOptions = [
        {
          id: "ac_id",
          options: accountOptions
        }
      ];

      if (this.selectedAccountId !== "all") {
        if (this.userType === "ac") {
          this.contentForm.account_mappings[0].scope = "ac";
        }
        this.contentForm.account_mappings[0].accounts = [
          {
            ac_id: this.selectedAccountId,
            sp_id: this.selectedServiceProviderId
          }
        ];
      }
      this.accountPermissions = this.contentForm.account_permissions;

      this.selected_roles = [];
      this.selected_accounts = [];
      if (
        this.id !== -1 &&
        this.contentForm.account_permissions &&
        this.contentForm.account_permissions.length > 0
      ) {
        this.contentForm.account_permissions.forEach(account_permission => {
          this.selected_roles.push(account_permission.user_role);
          account_permission.accounts.forEach(account =>
            this.selected_accounts.push(account.ac_id)
          );
        });
      }
    },
    addAccountPermissions() {
      this.accountPermissions.push({
        accounts: [],
        user_permissions: [],
        user_role: "",
        sync_permission: true
      });
    },
    removeAccountPermissions(index) {
      this.selected_roles = this.selected_roles.filter(
        selected_role =>
          selected_role !== this.accountPermissions[index].user_role
      );
      this.accountPermissions.splice(index, 1);
    },

    updateSelectedRoles(role) {
      this.selected_roles = this.selected_roles.filter(
        selected_role => selected_role !== role.old_role
      );
      this.selected_roles.push(role.role_id);
    },

    updateSelectedAccounts(accounts) {
      let account_ids = accounts.accounts.map(account => account.ac_id);
      this.selected_accounts = this.selected_accounts.filter(
        selected_account => !accounts.old_accounts.includes(selected_account)
      );
      account_ids.forEach(account_id =>
        this.selected_accounts.push(account_id)
      );
    }
  },

  /*async created() {
    console.log("at created hook " + this.id);
    if (this.id > 0) {
      await this.setScope(this.mappedScope(this.contentForm));
    }
  } */

  watch: {
    openModal: {
      handler(val) {
        if (val) {
          this.initializeModal();
        }
      }
    },

    formAction: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (val === "edit") {
          this.setEmptyPermission();
          if (this.contentForm.user_role_scope !== "system") {
            await this.setScope(this.mappedScope(this.contentForm));
          } else {
            await this.setScope("system");
          }
          this.contentForm.sync_permission = this.contentFormInfo.sync_permission;
          this.setPermissions(this.contentForm.user_role);
        }
        if (val === "add") {
          if (
            this.selectedServiceProviderId === "all" &&
            this.selectedAccountId === "all" &&
            this.userRole === "system"
          ) {
            this.contentForm.account_mappings = [];
            this.content.account_mappings = [];
            await this.setScope("system");
          } else {
            await this.setScope("ac");
          }
        }
      }
    },
    selectedServiceProviderId: {
      immediate: true,
      handler: async function() {
        await this.fetchAccountsForServiceProvider();
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}

.account-select {
  .account-name {
    font-weight: 900;
  }
}

.form-label {
  font-weight: 400;
  line-height: 1.2rem;
  padding: 0 0 10px 0;
  font-size: 1.2rem;
  color: #454545;
}

.editBtn {
  background: var(--site-secondary-background-color);
  color: var(--site-default-font-color);

  &:hover {
    color: var(--site-secondary-font-hover-color);
    background: var(--site-secondary-background-hover-color);
  }

  &:disabled {
    background: var(--site-secondary-background-disabled-color);
    border: none;
    color: var(--site-secondary-font-disabled-color);
  }
}
</style>
