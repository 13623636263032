<template>
  <div
    style="padding-top: 20px; padding-bottom: 20px;"
    v-loading="loadingComponent"
  >
    <el-row type="flex">
      <el-col :span="3">
        <el-button icon="el-icon-delete" circle @click="remove()"></el-button>
      </el-col>
      <el-col :span="12">
        <div class="form-label">{{ __("Role") }}</div>
        <el-select
          style="width: 100%"
          filterable
          :value="account_permission.user_role"
          @input="$set(account_permission, 'user_role', $event)"
          :placeholder="__('Select Role')"
          default-first-option
          v-loading="isFetchingRoles"
          @change="setPermissions"
        >
          <el-option
            v-for="item in availableRoles"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span v-if="disableAccounts" style="font-size: small; margin-top:27px;"
          >{{ __("Select a role to see available accounts.") }}
        </span>
      </el-col>
      <el-col :span="12" :offset="1">
        <div class="form-label">{{ __("Accounts") }}</div>
        <el-select
          popper-class="account-popper"
          value-key="ac_id"
          style="width: 100%"
          filterable
          :value="account_permission.accounts"
          @input="$set(account_permission, 'accounts', $event)"
          v-loading="loadingAccounts"
          default-first-option
          multiple
          @change="updateAvailableAccounts"
          :placeholder="__('Select')"
          :disabled="disableAccounts"
          :no-data-text="__('No accounts available for this role.')"
        >
          <el-option
            v-for="item in availableAccounts"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12" :offset="1">
        <el-checkbox
          :disabled="!account_permission.user_role"
          :value="account_permission.sync_permission"
          @input="$set(account_permission, 'sync_permission', $event)"
          style="padding-left:15px; padding-top: 28px"
          @change="handleSyncPermissionChange"
          >{{ __("Sync Permission") }}</el-checkbox
        >
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :offset="2" :span="12">
        <el-button
          :disabled="
            !permissions.length ||
              isSystemRole ||
              account_permission.sync_permission ||
              account_permission.user_role === ''
          "
          type="text"
          @click="showPermissions"
          v-loading="isFetchingPermissions"
          >{{ __("Edit Permissions") }}</el-button
        >
      </el-col>
    </el-row>
    <div
      v-if="
        this.show_permissions &&
          !isSystemRole &&
          !account_permission.sync_permission &&
          account_permission.user_role !== ''
      "
    >
      <el-row type="flex" style="max-height: 32px; margin-bottom: 10px">
        <el-col :offset="2">
          <div class="form-label">{{ __("Permissions") }}</div>
        </el-col>
        <el-col>
          <el-tooltip
            class="item"
            effect="dark"
            :content="__('Reset to permissions attached to selected role')"
            placement="left-end"
          >
            <el-button size="mini" round @click="resetToRolePermission">{{
              __("Reset To default")
            }}</el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="resetPermissionTooltip"
            placement="right-end"
          >
            <el-button
              v-show="id > 0"
              type="primary"
              size="mini"
              round
              @click="resetToOldPermissions"
              >{{ __("Reset to Old Permission") }}</el-button
            >
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :offset="2">
          <el-cascader-panel
            ref="permCascader"
            style="width: 100%"
            v-show="account_permission.user_role !== ''"
            :value="account_permission.user_permissions"
            @input="$set(account_permission, 'user_permissions', $event)"
            :key="cascaderKey"
            :options="role_options"
            :props="perm_props"
            clearable
            filterable
            v-loading="isFetchingPermissions"
            @change="permissionChange"
          ></el-cascader-panel>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";

export default {
  props: {
    account_permission: {
      type: Object,
      required: true
    },
    index: {
      required: true
    },
    id: {
      required: true
    },
    selected_roles: {
      required: true,
      type: Array
    },
    selected_accounts: {
      required: true,
      type: Array
    },
    available_roles: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      scope: "ac",
      perm_props: { multiple: true },
      selectedAccounts: [],
      show_permissions: false,
      old_permissions: [],
      old_role: "",
      old_accounts: [],
      role_options: []
    };
  },
  methods: {
    remove() {
      this.$emit("remove", this.index);
    },
    ...mapActions("permissions", {
      getAcScopePermissions: "getAcScopePermissions",
      getRolePermissions: "getRolePermissions",
      setEmptyPermission: "setEmptyPermission"
    }),
    async setPermissions(role_id) {
      await this.getRolePermissions({
        scope: this.scope,
        role_id: this.account_permission.user_role
      });
      this.$set(
        this.account_permission,
        "user_permissions",
        this.newRolePermissions(role_id)
      );
      this.role_options = this.permissions;
      this.$emit("roleSelected", { role_id: role_id, old_role: this.old_role });
      this.old_role = role_id;
    },

    getSubSetsForRole(selectedRole, availableRoles) {
      let rolesSubSet = _.filter(
        availableRoles,
        role =>
          _.intersectionWith(
            selectedRole.role_permissions,
            role.role_permissions,
            _.isEqual
          ).length === selectedRole.role_permissions.length
      );

      return rolesSubSet
        .filter(item => _.has(item, "name"))
        .map(item => item["name"]);
    },

    updateAvailableAccounts(accounts) {
      this.$emit("accountsSelected", {
        accounts: accounts,
        old_accounts: this.old_accounts
      });
      this.old_accounts = accounts.map(account => account.ac_id);
    },

    permissionChange(value) {
      let checkedNodes = this.$refs.permCascader.getCheckedNodes(true);
      let mandatory_permission = this.mandatory_permission(checkedNodes, value);
      if (
        mandatory_permission !== undefined &&
        mandatory_permission.length > 0
      ) {
        let clone_permission = _.clone(
          this.account_permission.user_permissions
        );
        clone_permission.push(mandatory_permission[0]);
        this.$set(
          this.account_permission,
          "user_permissions",
          clone_permission
        );
      }
    },

    handleSyncPermissionChange(value) {
      this.show_permissions = false;
      if (value) {
        this.resetToRolePermission();
      }
    },

    resetToRolePermission() {
      this.$set(
        this.account_permission,
        "user_permissions",
        this.defaultPermissions(this.account_permission)
      );
    },
    resetToOldPermissions() {
      if (this.account_permission.user_permissions.length) {
        this.$set(
          this.account_permission,
          "user_permissions",
          this.old_permissions
        );
      }
    },
    showPermissions() {
      this.show_permissions = true;
    }
  },
  computed: {
    ...mapGetters("app", {
      accountsForCreate: "accountsForCreate"
    }),
    ...mapState("serviceproviders", {
      loadingAccounts: "loading"
    }),
    ...mapState("roles", {
      roles: "roles",
      isFetchingRoles: "isLoading"
    }),
    ...mapState("permissions", {
      permissions: "permissions",
      isFetchingPermissions: "isLoading"
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId,
      userRole: state => state.userRole,
      userType: state => state.userType
    }),

    cascaderKey() {
      return this.id > 0 ? this.id : Math.random();
    },

    newRolePermissions() {
      return role_id => {
        return _.find(this.roles, function(role) {
          if (role.id === role_id) {
            return role.role_permissions;
          }
        }).role_permissions;
      };
    },

    isSystemRole() {
      let vm = this;
      if (vm.scope === "system") {
        return _.some(this.roles, function(role) {
          if (role.id === this.account_permission.user_role) {
            return role.scope === "system" && role.name === "system";
          }
        });
      }
      return false;
    },
    defaultPermissions() {
      return account_permission => {
        return _.find(this.roles, function(role) {
          return role.id === account_permission.user_role;
        }).role_permissions;
      };
    },
    availableRoles() {
      return this.roles.filter(role => {
        return (
          !this.selected_roles.includes(role.id) || role.id === this.old_role
        );
      });
    },

    loadingComponent() {
      return (
        this.isFetchingRoles ||
        this.isFetchingPermissions ||
        this.loadingAccounts
      );
    },

    availableAccounts() {
      let accounts = this.accountsForCreate.filter(account => {
        return (
          !this.selected_accounts.includes(account.value.ac_id) ||
          this.old_accounts.includes(account.value.ac_id)
        );
      });

      if (this.userType !== "ac") {
        return accounts;
      }

      if (
        this.account_permission.user_role !== "" &&
        !_.isEmpty(this.available_roles)
      ) {
        let selectedRole = _.find(this.available_roles, [
          "id",
          this.account_permission.user_role
        ]);

        let availableRoleNames = this.getSubSetsForRole(
          selectedRole,
          this.available_roles
        );

        let availableAccountIds = this.$auth
          .user()
          .roles_with_scope.filter(role_with_scope =>
            availableRoleNames.includes(role_with_scope.name)
          )
          .map(role => role.ac_id);

        return accounts.filter(account =>
          availableAccountIds.includes(account.value.ac_id)
        );
      }
      return [];
    },

    disableAccounts() {
      return this.userType === "ac" && this.account_permission.user_role === "";
    },

    /**
     * Content for the reset permission tooltip
     * @returns {String} Translated content for the reset permission tooltip
     */
    resetPermissionTooltip() {
      // eslint-disable-next-line
      return __("Reset user permissions to the state they were in when you initiated edit mode");
    }
  },
  beforeMount() {
    this.old_permissions = this.account_permission.user_permissions;
    this.old_role = this.account_permission.user_role;
    this.old_accounts = this.account_permission.accounts.map(
      account => account.ac_id
    );
  },

  async mounted() {
    if (this.id !== -1) {
      await this.getRolePermissions({
        scope: this.scope,
        role_id: this.account_permission.user_role
      });
      this.role_options = _.cloneDeep(this.permissions);
    }
  }
};
</script>

<style scoped>
.account-popper {
  overflow-y: auto;
}
.form-label {
  font-weight: 400;
  line-height: 1.2rem;
  padding: 0 0 10px 0;
  font-size: 1.2rem;
  color: #454545;
}
</style>
