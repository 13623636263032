<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Users')"
    />
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!this.canWrite()"
      >{{ __("Create User") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :show-only="showOnly"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
          <el-col class="user-filter" :span="3" slot="buttonGroup">
            <el-select
              v-if="showUserFilter"
              id="user-filter"
              :placeholder="__('Filter Users')"
              default-first-option
              v-model="showOnly"
              filterable
            >
              <el-option
                v-for="(item, index) in filterOptions"
                :label="item.label"
                :value="item.value"
                :key="index"
              />
            </el-select>
          </el-col>
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="usersTable"
            class="list-table"
            :data="users"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            v-loading="loading"
            v-show="this.canRead()"
          >
            <el-table-column :label="__('Email')" prop="email" />
            <el-table-column :label="__('Login time')" prop="login_time">
              <template slot-scope="{ row }">
                {{ formattedDateTime(row.login_time) }}
              </template>
            </el-table-column>
            <el-table-column :label="__('User Role')" prop="role_name">
              <template
                slot-scope="{ row }"
                v-if="Array.isArray(row.role_name)"
              >
                <div v-if="row.role_name.length > 1">
                  {{ row.role_name[0] }} + {{ row.role_name.length - 1 }} more
                </div>
                <div v-else-if="row.role_name.length === 1">
                  {{ row.role_name[0] }}
                </div>
                <div v-else></div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Service Provider')" prop="sp_name">
              <template slot-scope="{ row }" v-if="Array.isArray(row.sp_name)">
                <div v-if="row.sp_name === ''">
                  {{ row.sp_name }}
                </div>
                <div v-else-if="row.sp_name.length > 1">
                  {{ row.sp_name[0] }} + {{ row.sp_name.length - 1 }} more
                </div>
                <div v-else>
                  {{ row.sp_name[0] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Account')">
              <template slot-scope="{ row }" v-if="Array.isArray(row.scope)">
                <div v-if="row.scope === 'Service Provider'">
                  <el-tag type="danger">
                    {{ row.scope }}
                  </el-tag>
                </div>
                <div v-else>
                  <!--                  validate whether the user can access more than one account-->
                  <div v-if="row.scope === 'System'">
                    {{ row.scope }}
                  </div>
                  <div v-else-if="row.scope.length > 1">
                    {{ row.scope[0].ac_name }} + {{ row.scope.length - 1 }} more
                  </div>
                  <div v-else-if="!!row.scope.length">
                    {{ row.scope[0].ac_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Status')" prop="user_status_label" />
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { EventBus } from "@/EventBus";
import _ from "lodash";
const defaultFilterOptions = [
  {
    get label() {
      return __("All Users");
    },
    value: "all"
  },
  {
    get label() {
      return __("Only SP Users");
    },
    value: "sp"
  }
];

export default {
  name: "UsersManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["display_name", "user_id"],
      showOnly: "all",
      search: "",
      filters: ["display_name", "email"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },

  computed: {
    ...mapState("users", {
      users: state => state.users,
      loading: state => state.isLoading
    }),

    ...mapState("app", {
      userRole: state => state.userRole,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userAccountSwitcherOptions: state => state.userAccountSwitcherOptions
    }),
    ...mapGetters("app", {
      accountsForCreate: "accountsForCreate",
      formattedDateTime: "formattedDateTime",
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination",
      inSystemScope: "inSystemScope",
      inSpScope: "inSpScope"
    }),

    /**
     * Show user filter, only if it's a system user and
     * if either in sp or system scope
     */
    showUserFilter() {
      return (
        this.$auth.check("system") && (this.inSystemScope || this.inSpScope)
      );
    },

    /**
     * Provide filter options for user
     */
    filterOptions() {
      let filterOptions = defaultFilterOptions;
      if (this.inSystemScope) {
        filterOptions = _.union(filterOptions, [
          {
            get label() {
              return __("Only System Users");
            },
            value: "system"
          }
        ]);
      }
      return filterOptions;
    }
  },

  methods: {
    ...mapActions("users", {
      contentAPI: "getUsers",
      deleteContentMethod: "deleteUser",
      undoDeleteContent: "undoDeleteUser"
    }),
    handleClear() {
      this.$refs.usersTable && this.$refs.usersTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();

      //Block edit if User is Inactivate
      if (row.user_status !== "Active") {
        this.$message.info(
          // eslint-disable-next-line
          __("Please activate the user")
        );
        return;
      }

      const isServiceProviderAll = this.selectedServiceProviderId === "all";
      const isAccountAll = this.selectedAccountId === "all";

      //System Level & Login as a System User & Target user is a system user
      if (
        isServiceProviderAll &&
        isAccountAll &&
        row.current_role &&
        !row.ac_sp_map_role_permission.length &&
        this.userRole === "system"
      ) {
        this.handleEdit(null, row);
      } else if (
        //Sp Level
        (!isServiceProviderAll && isAccountAll) ||
        //Ac Level && Only one Ac under the Sp
        (!isServiceProviderAll &&
          !isAccountAll &&
          this.userAccountSwitcherOptions[0]["accounts"].length === 1)
      ) {
        const selectedUserAccScope = row.account_permissions.flatMap(ap =>
          ap.accounts.map(acc => acc.ac_id)
        );
        const loginUserAccScope = this.accountsForCreate.map(
          acc => acc.value.ac_id
        );
        if (
          selectedUserAccScope.every(ac_id => loginUserAccScope.includes(ac_id))
        ) {
          this.handleEdit(null, row);
        } else {
          //Target User has more Ac access under current Sp
          this.$message.info(
            // eslint-disable-next-line
                __("You don't have sufficient privileges to edit this user.")
          );
        }
      } else {
        const spNames = row.sp_name
          .filter((value, index, array) => array.indexOf(value) === index)
          .join(", ");
        const messageKey =
          row.sp_name.length > 1
            ? // eslint-disable-next-line
            __("Please select All Accounts under one of the following Service Providers [:spNames] to edit user info", { spNames })
            : // eslint-disable-next-line
              __("Please select All Accounts under Service Provider :spName to edit user info", { spName: row.sp_name[0] });

        this.$message.info(messageKey);
      }
    },
    tagsArray(tagsString) {
      return tagsString.split(",", 2);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          this.$refs.usersTable.setCurrentRow(this.users[val]);
        }
      }
    },
    selectedServiceProviderId: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          EventBus.$emit("list-changed", null);
        }
      }
    },
    selectedServiceProviderAccountCombination: {
      handler() {
        this.showOnly = "all";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
.user-filter {
  display: flex;
  padding-left: 15px;
}
</style>
